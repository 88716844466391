<template>
    <div>
        <template v-for="grade in gradesInResults">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in results.filter(group => group.grade === grade)"
                    :key="`card_${group.grade}`"
                    class="pa-5 mb-5 mr-5"
                    style="width: max-content"
                >
                    <h5>Оценки {{ group.name }}</h5>
                    <div class="d-flex align-center">
                        <span class="d-flex align-center mr-4">
                            Средняя 
                            <value-cell
                                :value="getAvarageScore(group.items.map(student => student.scores).flat())"
                                :compare-value="getAvarageScore(getScoresOfGroupFromResults(prevResults, group.group_id))"
                                class="ml-2"
                            />
                        </span>
                        <span class="d-flex align-center mr-4">
                            Максимальная 
                            <value-cell
                                :value="getMaxScore(group.items.map(student => student.scores).flat())"
                                :compare-value="getMaxScore(getScoresOfGroupFromResults(prevResults, group.group_id))"
                                class="ml-2"
                            />
                        </span>
                        <span class="d-flex align-center">
                            Минимальная
                            <value-cell
                                :value="getMinScore(group.items.map(student => student.scores).flat())"
                                :compare-value="getMinScore(getScoresOfGroupFromResults(prevResults, group.group_id))"
                                class="ml-2"
                            />
                        </span>
                    </div>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        }
    },
    methods: {
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return Math.round(avg)
        },
        getMaxScore (scores) {
            return Math.max(...scores)
        },
        getMinScore (scores) {
            return Math.min(...scores)
        },
        getScoresOfGroupFromResults (prevResults, group_id) {
            return prevResults.find(group => group.group_id === group_id)?.items.map(student => student.scores).flat() || []
        }
    }
}
</script>