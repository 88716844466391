<template>
    <div>
        <template v-for="grade in gradesInResults">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in results.filter(group => group.grade === grade)"
                    :key="`card_${group.grade}`"
                    class="pa-5 mb-5 mr-5"
                    style="width: max-content"
                >
                    <strong class="d-flex mb-4">Оценки {{ group.name }}</strong>
                    <div class="d-flex flex-column justify-center">
                        <div class="d-flex align-center w-100">
                            <h5>Ученик</h5>
                            <v-spacer />
                            <h5>Оценка</h5>
                        </div>
                        <span
                            v-for="student in group.items"
                            :key="student.student_id"
                            class="d-flex align-center"
                        >
                            <span>{{ student.name }}</span>
                            <v-spacer />
                            <value-cell
                                :value="getAvarageScore(student.scores)"
                                :compare-value="getAvarageScore(getScoresOfStudentFromResults(prevResults, group.group_id, student.student_id))"
                                class="ml-2"
                            />
                        </span>
                    </div>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        }
    },
    methods: {
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return Math.round(avg)
        },
        getScoresOfStudentFromResults (prevResults, group_id, student_id) {
            return prevResults.find(group => group.group_id === group_id)?.items
                    .find(student => student.student_id === student_id)?.scores || []
        }
    }
}
</script>