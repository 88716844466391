<template>
    <div>
        <template v-for="grade in gradesInResults">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in results.filter(group => group.grade === grade)"
                    :key="`card_${group.grade}`"
                    class="pa-5 mb-5 mr-5"
                    style="width: max-content"
                >
                    <strong class="d-flex mb-4">Степень освоения тем классом {{ group.name }}</strong>
                   
                    <v-data-table
                        dense
                        :items-per-page="-1"
                        hide-default-footer
                        calculate-widths
                        :headers="getHeaders(group)"
                        :items="getModels(group)"
                        item-key="id"
                        class="elevation-1"
                    >
                        <template
                            v-for="(theme, ti) in allThemes"
                            #[`header.${theme}`]
                        >
                            <span :key="ti" :title="theme">{{limitStringLength(theme)}}</span>
                        </template> 
                        <template
                            v-for="(theme, ti) in allThemes"
                            #[`item.${theme}`]="{ item }"
                        >
                            <value-cell
                                :key="ti"
                                :value="getAvarageScore(item[theme])"
                                :compare-value="getAvarageScore(getScoresOfStudentFromResults(prevResults, group.group_id, theme, item.student_id))"
                                postfix="%"
                            />
                        </template> 
                    </v-data-table>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'
import StringHelper from '@/helpers/StringHelper'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        },
        allThemes () {
            return Array.from(new Set(this.results.map(group => group.items.map(student => student.scores.map(score => score.theme)).flat()).flat()))
        }
    },
    methods: {
        getHeaders (group) {
            const themes = Array.from(new Set(group.items.map(student => student.scores.map(score => score.theme)).flat()))
            return [
                { text: 'Ученик', value: 'name' },
                ...themes.map(item => ({
                                    text: item,
                                    value: item
                                }))
            ]
        },
        getModels(group) {
            return group.items.map(student => {
                const fields = {}
                student.scores.forEach(item => {
                    fields[item.theme] = item.scores
                })
                return {
                        id: student.student_id,
                        name: student.name,
                        ...fields
                    }
            })
        },
        limitStringLength (str) {
            return StringHelper.limitStringLength(str, 35, '...')
        },
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return Math.round(avg)
        },
        getScoresOfStudentFromResults (results, group_id, theme, student_id) { // 
            return results.find(group => group.group_id === group_id)?.items
                            .find(student => student.student_id === student_id)?.scores
                            .find(item => item.theme === theme)?.scores || []
        }
    }
}
</script>