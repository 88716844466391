<template>
    <div>
        <template v-for="grade in gradesInResults">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in results.filter(group => group.grade === grade)"
                    :key="`card_${group.grade}`"
                    class="pa-5 mb-5 mr-5"
                    style="width: max-content"
                >
                    <strong class="d-flex mb-4">Класс {{ group.name }}</strong>
                    <v-data-table
                        dense
                        :items-per-page="-1"
                        hide-default-footer
                        calculate-widths
                        :headers="getHeaders()"
                        :items="getModels(group)"
                        class="elevation-1"
                    >
                        <template #[`item.theme`]="{ item }">
                            <span :title="item.theme">{{ limitStringLength(item.theme) }}</span>
                        </template>
                        <template #[`item.value`]="{ item }">
                            <value-cell
                                :value="getAvarageScore(getScoresOfGroupFromResults(results, group.group_id, item.theme))"
                                :compare-value="getAvarageScore(getScoresOfGroupFromResults(prevResults, group.group_id, item.theme))"
                                postfix="%"
                            />
                        </template>
                    </v-data-table>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'
import StringHelper from '@/helpers/StringHelper'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        },
        allThemes () {
            return Array.from(new Set(this.results.map(group => group.items.map(student => student.scores.map(score => score.theme)).flat()).flat()))
        }
    },
    methods: {
        limitStringLength (str) {
            return StringHelper.limitStringLength(str, 40, '...')
        },
        getHeaders () {
            return [
                { text: 'Тема', value: 'theme' },
                { text: 'Степень освоения', value: 'value' }
            ]
        },
        getModels(group) {
            return this.allThemes.map(theme => ({
                theme,
                value: this.getAvarageScore(this.getScoresOfGroupFromResults(this.results, group.group_id, theme))
            }))
        },
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            return Math.round(avg)
        },
        getScoresOfGroupFromResults (results, group_id, theme) {
            return results.find(group => group.group_id === group_id)?.items
                .map(student => student.scores.find(score => score.theme === theme)?.scores).flat() || []
        }
    }
}
</script>