<template>
    <div>
        <div class="text-h6">Успеваемость</div>

        <v-alert v-if="!_.isNil(error)" dense type="error">
            {{ error }}
        </v-alert>

        <v-progress-linear
            v-if="loading.initial"
            :size="50"
            color="purple darken-2"
            indeterminate
        />
        <template v-else>
            <div class="d-flex align-center">
                <v-label>Фильтр</v-label>
                <v-divider vertical class="mx-5"/>
                <v-select
                    v-model="filter.subject"
                    :items="subjects"
                    label="Предмет"
                    color="purple darken-2"
                    class="mr-4"
                    style="max-width: 300px;"
                    @change="onFilterChange"
                />
                <v-btn small class="mr-4" @click="dialogs.date = true">{{ filter.date ? `Выбран период: ${filter.date}` : 'Выбрать период' }}</v-btn>
                <v-dialog v-model="dialogs.date" max-width="290px">
                    <v-card>
                        <v-date-picker
                            v-model="filter.date"
                            type="month"
                            color="purple darken-2"
                            @change="onDatePickerChange"
                        ></v-date-picker>
                        <v-card-actions>
                            <v-btn color="red" dark @click.prevent.stop="resetDate">
                                Сбросить
                            </v-btn>
                            
                            <v-spacer></v-spacer>

                            <v-btn color="purple darken-2" dark @click.prevent.stop="dialogs.date = false">
                                Применить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-spacer />
                <v-btn
                    :disabled="!filter.started_at || !filter.ended_at || !filter.subject"
                    color="primary"
                    @click="show"
                >Отобразить</v-btn>
            </div>

            <div style="height: 10px">
                <v-progress-linear
                    v-if="loading.show"
                    :size="50"
                    indeterminate
                />
            </div>

            <p v-if="!loading.show && !results.length">{{onceSearched ? 'Данные по указанным параметрам не найдены.' : 'Укажите параметры фильтра и нажмине "Отобразить"'}}</p>

            <div v-if="results.length && !loading.show" class="d-flex flex-column">
                
                <v-tabs :value="currentTab" class="mb-5" @change="onTabChanged">
                    <v-tab v-for="n in tabs.length" :key="n">{{ tabs[n - 1] }}</v-tab>
                </v-tabs>
                <scores-by-subject-page v-if="currentTab === 0" :results="results" :prev-results="prevResults" />
                <scores-by-group-page v-if="currentTab === 1" :results="results" :prev-results="prevResults" />
                <scores-by-subject-by-theme-page v-if="currentTab === 2" :results="results" :prev-results="prevResults" />
                <scores-by-group-by-theme-page v-if="currentTab === 3" :results="results" :prev-results="prevResults" />
            </div>
        </template>
    </div>
</template>

<script>
import ScoresBySubjectPage from './ScoresBySubjectPage.vue'
import ScoresByGroupPage from './ScoresByGroupPage.vue'
import ScoresBySubjectByThemePage from './ScoresBySubjectByThemePage.vue'
import ScoresByGroupByThemePage from './ScoresByGroupByThemePage.vue'
import subjects from '@/subjects'
import TimeHelper from '@/helpers/TimeHelper'

const maxRate = 5
const tabToMethod = [
                'retrieveResultsBySubject',
                'retrieveResultsBySubject',
                'retrieveResultsByTheme',
                'retrieveResultsByTheme'
            ]

// TODO: в отчете не учитываются оценки из отчетов фиоко ВПР. Надо исправить? (уточнить)

export default {
    components: {
        ScoresBySubjectPage, ScoresByGroupPage,
        ScoresBySubjectByThemePage, ScoresByGroupByThemePage
    },
    data () {
        return {
            onceSearched: false,
            error: null,
            groups: [],
            students: [],
            loading: {
                initial: false,
                show: false
            },
            dialogs: {
                date: false
            },
            filter: {
                date: null,
                started_at: null,
                ended_at: null,
                subject: null
            },
            results: [],
            prevResults: [],
            currentTab: 0
        }
    },
    async created () {
        if (this.$store.state.performance.filter) {
            this.filter = _.cloneDeep(this.$store.state.performance.filter)
        }
        this.loading.initial = true
        await Promise.all([this.fetchGroups(), this.fetchStudents()])
        
        this.loading.initial = false
    },
    computed: {
        subjects () {
            return subjects
        },
        tabs () {
            return [
                'Статистика по предметам',
                'Статистика по классу',
                'Статистика по темам по классам',
                'Статистика по темам в классе'
            ]
        }
    },
    methods: {
        async fetchGroups () {
            try {
                const { success, error, data } = await this.$store.dispatch('group/list', {
                            pagination: 0,
                            fields: 'id,name,grade'
                        })
                
                if (!success)
                    throw new Error(error)

                this.groups = data.items?.map(item => ({
                    text: item.name,
                    value: item.id,
                    grade: item.grade
                }))
                return true
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
                return false
            }
        },
        async fetchStudents () {
            try {
                const { success, error, data } = await this.$store.dispatch('user/list', {
                            pagination: 0,
                            fields: 'id,name',
                            filter: { role: 'student' }
                        })
                
                if (!success)
                    throw new Error(error)

                this.students = data.items?.map(item => ({
                    text: item.name,
                    value: item.id
                }))
                return true
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
                return false
            }
        },
        async retrieveResultsBySubject (filter) {
            const { success: s1, error: e1, data: d1 } = await this.$store.dispatch('assigned_job/list', {
                    fields: ['group_id'].join(','),
                    filter: {
                        status: 'past',
                        checked: 1,
                        started_at: { '>=': filter.started_at },
                        ended_at: { '<=': filter.ended_at },
                        subject: filter.subject
                    },
                    pagination: 0
                })
            if (!s1)
                throw new Error(e1)

            const assigned_job_ids = d1.items.map(item => item.id)

            const { success: s2, error: e2, data: d2 } = await this.$store.dispatch('result/list', {
                fields: ['all_parts_score_percent', 'student_id', 'assigned_job_id'].join(','),
                filter: {
                    assigned_job_id: { in: assigned_job_ids }
                },
                pagination: 0
            })

            if (!s2)
                throw new Error(e2)

            const results = []
            // Группируем по ученикам
            d2.items.forEach(item => {
                const group_id = d1.items.find(assigned_job => assigned_job.id === item.assigned_job_id)?.group_id
                let groupChunk = results.find(r => r.group_id === group_id)
                
                if (!groupChunk) {
                    const group = this.groups.find(g => g.value === group_id)

                    groupChunk = {
                        group_id,
                        name: group?.text || 'Неизвестно',
                        grade: group?.grade || 'Неизвестно',
                        items: []
                    }
                    results.push(groupChunk)
                }

                let student = groupChunk.items.find(student => student.student_id === item.student_id)

                if (!student) {
                    student = {
                        student_id: item.student_id,
                        name: this.students.find(s => s.value === item.student_id)?.text,
                        scores: []
                    }
                    groupChunk.items.push(student)
                }

                student.scores.push(this.getRate(item.all_parts_score_percent))
            })
            // Sort by grade
            return results.sort((a, b) => a.grade - b.grade)
        },
        async retrieveResultsByTheme (filter) {
            const { success: s1, error: e1, data: d1 } = await this.$store.dispatch('assigned_job/list', {
                    fields: ['group_id'].join(','),
                    filter: {
                        status: 'past',
                        checked: 1,
                        started_at: { '>=': filter.started_at },
                        ended_at: { '<=': filter.ended_at },
                        subject: filter.subject
                    },
                    pagination: 0
                })
            if (!s1)
                throw new Error(e1)

            const assigned_job_ids = d1.items.map(item => item.id)

            const { success: s2, error: e2, data: result_of_tasks } = await this.$store.dispatch('result_of_task/list', {
                fields: ['task_id', 'score_percent', 'student_id', 'assigned_job_id'].join(','),
                filter: {
                    assigned_job_id: { in: assigned_job_ids }
                },
                pagination: 0
            })

            if (!s2)
                throw new Error(e2)

            const uniqueTaskIds = Array.from(new Set(result_of_tasks.items.map(item => item.task_id)))
            const { success: s3, error: e3, data: themes } = await this.$store.dispatch('theme_of_task/list', {
                fields: ['task_id', 'theme'].join(','),
                filter: {
                    task_id: { in: uniqueTaskIds }
                },
                pagination: 0
            })

            if (!s3)
                throw new Error(e3)

            const themesToTaskIds = []

            uniqueTaskIds.forEach(task_id => {
                const themeObject = themes.items.find(item => item.task_id === task_id)

                if (!themeObject) { return }
                let ttti = themesToTaskIds.find(item => item.theme === themeObject.theme)

                if (!ttti) {
                    ttti = { theme: themeObject.theme, task_id: [] }
                    themesToTaskIds.push(ttti)
                }

                ttti.task_id.push(task_id)
            })

            const results = []
            // Группируем по ученикам
            result_of_tasks.items.forEach(item => {
                const group_id = d1.items.find(assigned_job => assigned_job.id === item.assigned_job_id)?.group_id
                let groupChunk = results.find(r => r.group_id === group_id)
                
                if (!groupChunk) {
                    const group = this.groups.find(g => g.value === group_id)

                    groupChunk = {
                        group_id,
                        name: group?.text || 'Неизвестно',
                        grade: group?.grade || 'Неизвестно',
                        items: []
                    }
                    results.push(groupChunk)
                }

                let student = groupChunk.items.find(student => student.student_id === item.student_id)

                if (!student) {
                    student = {
                        student_id: item.student_id,
                        name: this.students.find(s => s.value === item.student_id)?.text,
                        scores: []
                    }
                    groupChunk.items.push(student)
                }
                const theme = themesToTaskIds.find(_item => _item.task_id.includes(item.task_id))?.theme
                if (!theme) { return }
                
                let themeScores = student.scores.find(themeScores => themeScores.theme === theme)

                if (!themeScores) {
                    themeScores = { theme, scores: [] }
                    student.scores.push(themeScores)
                }
                themeScores.scores.push(item.score_percent)
            })
            // Sort by grade
            return results.sort((a, b) => a.grade - b.grade)
        },
        async show () {
            this.onceSearched = true
            this.loading.show = true
            this.results = []
            this.error = null
            
            try {
                this.results = await this[tabToMethod[this.currentTab]](this.filter)
                this.prevResults = await this[tabToMethod[this.currentTab]](this.getPrevMonthFilter())
            } catch (e) {
                console.error(e)
                this.error = e?.message || 'Неизвестная ошибка при запросе на сервер'
            } finally {
                this.loading.show = false
            }
        },
        getRate (score_percent) {
            return Math.round((maxRate / 100) * score_percent)
        },
        onDatePickerChange (payload) {
            const { started_at, ended_at } = this.splitDateStringToTimeInterval(payload)
            this.filter.started_at = started_at
            this.filter.ended_at = ended_at
            this.onFilterChange()
        },
        onFilterChange () {
            this.$store.dispatch('performance/setFilter', this.filter)
        },
        splitDateStringToTimeInterval (dateString) {
            const startDate = this.$moment(dateString).startOf('month')
            const endDate = this.$moment(dateString).endOf('month')
            return { started_at: startDate.format('X'), ended_at: endDate.format('X') }
        },
        getPrevMonthFilter () {
            const prevMonthFilter = {...this.filter}
            let [year, month] = prevMonthFilter.date.split('-').map(value => parseInt(value))
            if (month === 1) {
                year--
                month = 12
            }
            else
                month--
            
            prevMonthFilter.date = `${year}-${TimeHelper.leadZero(month)}`
            const { started_at, ended_at } = this.splitDateStringToTimeInterval(prevMonthFilter.date)
            prevMonthFilter.started_at = started_at
            prevMonthFilter.ended_at = ended_at
            return prevMonthFilter
        },
        resetDate () {
            this.filter.date = null
            this.filter.started_at = null
            this.filter.ended_at = null
            this.dialogs.date = false
            this.onFilterChange()
        },
        onTabChanged (value) {
            let willShow = false
            
            if (tabToMethod[value] !== tabToMethod[this.currentTab])
                willShow = true

            this.currentTab = value
            
            if (willShow)
                this.show()
        }
    }
}
</script>